import { createStore } from 'vuex'
import search from './modules/search'
import changeNum from './modules/changNumber'
import gpsAudit from './modules/gpsAudit'
import maintain from './modules/maintain'
import optimize from './modules/optimizeOptions'
import OrderManage from './modules/orderMange'
import resetId from './modules/resetId'
import system from './modules/system'
import commonData from './modules/commonData'
import permission from './modules/permission'
import http, { http_clean } from '@/utils/request'

export default createStore({
  modules: {
    search,
    changeNum,
    gpsAudit,
    maintain,
    optimize,
    OrderManage,
    resetId,
    system,
    commonData,
    permission
  },
  state: {
    userId: '', // 用户ID
    screenHeight: document.documentElement.clientHeight,
    screenWidth: document.documentElement.clientWidth,
    homeState: {
      onlineCar: '0', //首页在线车辆总数
      offlineCar: '0', //首页离线车辆总数
      chartData1: {},
      chartData2: {},
      chartData3: {},
      chartData4: {}
    },
    homeScroll: 0, //缓存首页页面滚动位置
    oriScroll: 0, //缓存定位页面滚动位置
    searScroll: 0, //缓存搜索车辆页面滚动位置
    token: '',
    loading: false,
    loadText: '拼命加载中...', // loading 文本
    // 车辆定位需要查看的详细信息
    carInfo: {
      carNumber: '',
      deviceId: '',
      weight: '',
      carUploadDate: '', // 重量时间
      x: '',
      y: '',
      updateTime: ''
    },
    isIOS: false
  },
  getters: {
    // 计算首页总计车辆
    totalNumber(state) {
      return Number(state.homeState.onlineCar) + Number(state.homeState.offlineCar)
    }
  },
  mutations: {
    // 获取用户 ID
    updateUserID(state, userId) {
      state.userId = userId
    },
    // 修改定位滚动位置
    updateOriScroll(state, position) {
      state.oriScroll = position
    },
    // 搜索车辆的滚动位置
    updateSearScroll(state, position) {
      state.searScroll = position
    },
    // 展示全局 loading
    showLoading(state) {
      state.loading = true
    },
    // 修改 loading 文字
    changeLoadText(state, data) {
      if (data) {
        state.loadText = data
        return
      }
      state.loadText = '拼命加载中...'
    },
    // 隐藏全局 loading
    hideLoading(state) {
      state.loading = false
    },
    // 接口返回的 token
    token(state, token) {
      state.token = token
    },
    onlineCar(state, data) {
      state.homeState.onlineCar = data[0] || '0'
      state.homeState.offlineCar = data[1] || '0'
    },
    // 首页 echart 数据
    homeChartRequest1(state, data) {
      state.homeState.chartData1 = data
    },
    homeChartRequest2(state, data) {
      state.homeState.chartData2 = data
    },
    homeChartRequest3(state, data) {
      state.homeState.chartData3 = data
    },
    homeChartRequest4(state, data) {
      state.homeState.chartData4 = data
    },
    updateHomeScroll(state, scrollTop) {
      state.homeScroll = scrollTop
    },
    updateCarInfo(state, data) {
      Object.keys(data).forEach(key => {
        state.carInfo[key] = data[key]
      })
    },
    updateIOSState(state, data) {
      state.isIOS = data
    }
  },
  actions: {
    async homeOnlineRequest({ commit }) {
      const data = await http_clean.post('mainstat/online.html')
      if (data.data && data.data.length) {
        const numArr = data.data.split(',')
        commit('onlineCar', numArr)
      }
    },
    homeChartRequest({ commit }, data) {
      return postChartRequest(data, commit)
    }
  }
})
const postChartRequest = async (data, commit) => {
  const chartData = await http_clean.post('/car/statisticsInstallDeviceCountByType.html', data)
  switch (data.type) {
    case 1:
      commit('homeChartRequest1', chartData)
      break
    case 2:
      commit('homeChartRequest2', chartData)
      break
    case 3:
      commit('homeChartRequest3', chartData)
      break
    case 4:
      commit('homeChartRequest4', chartData)
      break
    default:
      break
  }
}
