import moment from 'moment'
/**
 * 格式化时间字符串 YYYY-MM-DD HH:mm:ss
 * @param {Date} date
 * @return String
 */
export function formatTimeStr(date) {
  return moment(date).format('YYYY-MM-DD HH:mm:ss')
}
/**
 * 获取 Unix 时间值, 即 YYYY-MM-DD HH:mm:ss 转时间戳
 * @param {Date} date
 * @return Number
 */
export function getMilliSecond(date) {
  return moment(date).valueOf()
}
/**
 * 获取两个时间的时间差，单位毫秒，第一项减去第二项
 * @param {Date} first 被减数
 * @param {Date} second 减数
 * @return Number
 */
export function getTimeDiff(first, second) {
  return moment(first).diff(moment(second))
}
/**
 * 向后推算增加时间，多次调用可以考虑柯里化
 * @param { String } type 需要转换的时间维度，如'days','seconds'
 * @param { Number } spec 需要增加的时间
 * @param { Date | DateString } origin 原始时间
 * @return String 格式化后的时间字符串
 */
export function increaseDate(type, spec, origin) {
  return moment(origin).add(spec, type).format('YYYY-MM-DD HH:mm:ss')
}
/**
 * 向前推算减少时间
 * @param { String } type 需要转换的时间维度，如'days','seconds'
 * @param { Number } spec 需要减少的时间
 * @param { Date | DateString } origin 原始时间
 * @return String 格式化后的时间字符串
 */
export function decreaseDate(type, spec, origin) {
  return moment(origin).subtract(spec, type).format('YYYY-MM-DD HH:mm:ss')
}

/**
 * 格式化公司数据，平级转树状
 * @param {Array} data 接口返回的平级公司数据
 * @returns {Array} 返回树状结构
 */
export function toTree(data) {
  const companyList = []
  const companyInfo = {} // 遍历建立映射，映射中保存company的信息，使用id作为索引

  // 需要的结构 { id: '', text: '', children: []}
  // 返回数据中已有text 现在遍历创建children
  data.forEach(com => {
    companyInfo[com.id] = com
    com.children = []
  })
  data.forEach(company => {
    // parant为'#'没有父级，压入返回值，有父级通过companyInfo中的id索引，将company放入父级children中
    company.parent == '#' ? companyList.push(company) : companyInfo[company.parent].children.push(company)
  })
  return companyList
}

// fn是包装事件回调, interval是时间间隔的阈值
export function throttle(fn, interval) {
  // last为上一次触发回调的时间
  let last = 0
  // 将throttle处理结果当作函数返回
  return function (...args) {
    // 保留调用时的this上下文
    let context = this
    // 记录本次触发回调的时间
    let now = +new Date()
    // 判断上次触发的时间和本次触发的时间差是否小于时间间隔的阈值
    if (now - last >= interval) {
      // 如果时间间隔大于我们设定的时间间隔阈值，则执行回调
      last = now
      fn.apply(context, args)
    }
  }
}

// 防抖
export function debounce(func, wait) {
  let lastCallTime, timer
  return function (...args) {
    // 获取当前时间戳
    const date = Date.now()
    const context = this
    // 如果时间间隔小于间隔，等待 wait 毫秒后执行，清除之前的定时器
    if (date - lastCallTime < wait) {
      clearTimeout(timer)
    }
    // 重置上次触发时间
    lastCallTime = date
    timer = setTimeout(() => {
      func.apply(context, args)
      timer = null
    }, wait)
  }
}

/**
 *  功能：校验时间格式（时间范围校验）
 *  @param1 startTime: 起始时间
 *  @param1 endTime: 结束时间
 *  @param1 maxDays: 不能超过最大天数
 *
 */
export function correctTimeParams(startTime, endTime, maxDays) {
  let result = {
    status: false,
    message: ''
  }
  if (!startTime && !endTime) {
    result.status = true
    return result
  }
  if (!startTime) {
    result.message = '开始时间不能为空'
    return result
  }
  if (!endTime) {
    result.message = '结束时间不能为空'
    return result
  }
  //判断传入进来的时间格式,分yyyy-MM-dd HH:mm:ss 和 yyyy-MM-dd两种格式校验
  const startTimeType = startTime.split(':').length
  const endTimeType = endTime.split(':').length
  if (startTimeType == endTimeType) {
    if (startTimeType !== 3) {
      if (!checkDayFormat(startTime)) {
        result.message = '开始时间格式不正确'
        return result
      }
      if (!checkDayFormat(endTime)) {
        result.message = '结束时间格式不正确'
        return result
      }
    } else {
      if (!checkDateFormat(startTime)) {
        result.message = '开始时间格式不正确'
        return result
      }
      if (!checkDateFormat(endTime)) {
        result.message = '结束时间格式不正确'
        return result
      }
    }
  } else {
    result.message = '开始时间与结束时间格式不对应'
    return result
  }
  //校验时间跨度是否在允许时间范围内
  let startTimestamp = new Date(startTime).getTime()
  let endTimestamp = new Date(endTime).getTime()
  let timeDiff = endTimestamp - startTimestamp
  if (timeDiff < 0) {
    result.message = '时间选择不正确,结束时间应大于起始时间'
    return result
  }
  if (maxDays > 0 && timeDiff > maxDays * 24 * 60 * 60 * 1000) {
    result.message = '时间区间不能超过' + maxDays + '天'
    return result
  }
  result.status = true
  return result
}

/**
 *  功能：校验时间格式（yyyy-mm-dd）
 *  @param1 date: 时间字符串
 */
function checkDayFormat(date) {
  const reg = /^(\d+)-(\d{1,2})-(\d{1,2})$/
  const r = date.match(reg)
  if (r == null) return false
  r[2] = r[2] - 1
  const d = new Date(r[1], r[2], r[3])
  if (d.getFullYear() != r[1]) return false
  if (d.getMonth() != r[2]) return false
  if (d.getDate() != r[3]) return false
  return true
}
/**
 *  功能：校验时间格式（yyyy-mm-dd hh:ii:ss）
 *  @param1 date: 时间字符串
 */
function checkDateFormat(date) {
  const reg = /^(\d+)-(\d{1,2})-(\d{1,2}) (\d{1,2}):(\d{1,2}):(\d{1,2})$/
  const r = date.match(reg)
  if (r == null) return false
  r[2] = r[2] - 1
  const d = new Date(r[1], r[2], r[3], r[4], r[5], r[6])
  if (d.getFullYear() != r[1]) return false
  if (d.getMonth() != r[2]) return false
  if (d.getDate() != r[3]) return false
  if (d.getHours() != r[4]) return false
  if (d.getMinutes() != r[5]) return false
  if (d.getSeconds() != r[6]) return false
  return true
}

//获取echart折线图 线的颜色
export function lineGetColor(sort) {
  const colorArr = [
    '#2f4554',
    '#61a0a8',
    '#d48265',
    '#91c7ae',
    '#749f83',
    '#ca8622',
    '#bda29a',
    '#6e7074',
    '#546570',
    '#c4ccd3'
  ]
  const numver = sort % colorArr.length
  return colorArr[numver]
}
/**
 * 功能：保留小数点后2位
 * @param1 param: 数字或者字符串
 */
export function twoDecimal(param) {
  if (param == 0) {
    return 0.0
  }
  if (!param) return
  param = Number(param)
  let result = 0
  if (param || param > -1) {
    result = param.toFixed(2)
  }
  return result
}
/**
 *  功能：时间秒转化为具体时间（xx天xx时xx分xx秒）
 *  @param1 result: 秒
 */
export function secondToDate(result) {
  const d = Math.floor(result / 86400)
  const h = Math.floor((result / 3600) % 24)
  const m = Math.floor((result / 60) % 60)
  const s = Math.floor(result % 60)
  if (Math.floor(result / 86400) > 0) {
    return d + '天' + h + '时' + m + '分' + s + '秒'
  } else if (Math.floor((result / 3600) % 24) > 0) {
    return h + '时' + m + '分' + s + '秒'
  } else if (Math.floor((result / 60) % 60) > 0) {
    return m + '分' + s + '秒'
  } else {
    return s + '秒'
  }
}
//深拷贝浅拷贝
export function deepClone(obj) {
  let objClone = Array.isArray(obj) ? [] : {}
  if (obj && typeof obj === 'object') {
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        //判断ojb子元素是否为对象，如果是，递归复制
        if (obj[key] && typeof obj[key] === 'object') {
          objClone[key] = deepClone(obj[key])
        } else {
          //如果不是，简单复制
          objClone[key] = obj[key]
        }
      }
    }
  }
  return objClone
}
/**
 * 包装 await 的函数，减少 try-catch 的使用
 * @param { Promise } promise 异步任务
 * @return { Array } 数组中两个值，第一项是错误对象，没有就为 null，第二项为成功后返回的数据，失败不传为 undefined
 */
export function to(promise) {
  return promise
    .then(data => {
      return [null, data]
    })
    .catch(err => [err])
}

/**
 * 对于报存到本地的信息进行转化存储
 * @param {String} key 保存的键名
 * @param {Object} info 需要转化的数据
 */
export function encodeLocalStr(key, info) {
  const str = window.btoa(encodeURIComponent(JSON.stringify(info)))
  localStorage.setItem(key, str)
}

export function decodeLocalStr(key) {
  const item = localStorage.getItem(key)
  if (item) {
    return JSON.parse(decodeURIComponent(window.atob(item)))
  }
  return {}
}
