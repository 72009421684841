import { createApp } from 'vue'
// 先倒入第三方组件，后续引入项目样式
// 可提升项目样式权重
import vantComponents from './components/vant'
import router from './router'
import store from './store'
import App from './App.vue'
import NavBar from '@/views/components/NavBar.vue'
// 不同浏览器一些默认的html标签的展示行为是不一致的，normalize.css 使样式在所有浏览器上保持一致
import 'normalize.css'
import '@/style/index.scss'
import Vconsole from 'vconsole'

const app = createApp(App)

if (process.env.VUE_APP_MODE !== 'prod' && process.env.VUE_APP_MODE !== 'dev') {
  new Vconsole()
}
// process.env.VUE_APP_MODE !== 'prod' && new Vconsole()
// new Vconsole()

vantComponents.forEach(el => {
  app.component(el.name, el)
})

app.component('NavBar', NavBar)

app.use(store).use(router).mount('#app')
