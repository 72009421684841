import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/login/Login'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from '@/store'
import commonRouters from './common'
import searchRouters from './search'
import settingsRouters from './setting'
import orientationRouters from './orientation'
import { requestCount } from '@/utils/request'

/*
meta : isSub 是否是子级页面，子级页面没有底部docker
       title 顶部展示的title
       keepAlive 是否缓存页面
       auth 是否需要权限，默认没有
*/
const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "home" */ '../layouts/BasicLayout'),
    children: [
      {
        path: '/',
        redirect: '/home'
      },
      {
        path: '/home',
        name: 'Home',
        meta: {
          title: '首页',
          keepAlive: true,
          isSub: false
        },
        component: () => import(/* webpackChunkName: "home" */ '../views/home/home/index.vue')
      },
      {
        path: '/day-install',
        name: 'DayInstallInfo',
        meta: {
          title: '安装统计',
          isSub: true
        },
        component: () => import(/* webpackChunkName: "home" */ '../views/home/day-install/index.vue')
      },
      {
        path: '/month-install',
        name: 'MonthInstallInfo',
        meta: {
          title: '安装统计',
          isSub: true
        },
        component: () => import(/* webpackChunkName: "home" */ '../views/home/month-install/index.vue')
      },
      {
        path: '/inventory',
        name: 'Inventory',
        meta: {
          title: '厂区库存数据',
          isSub: true
        },
        component: () => import(/* webpackChunkName: "home" */ '../views/home/inventory/index.vue')
      },
      {
        path: '/renew',
        name: 'Renew',
        meta: {
          title: '续费数统计',
          isSub: true
        },
        component: () => import(/* webpackChunkName: "home" */ '../views/home/renew/index.vue')
      },

      {
        path: '/user',
        name: 'User',
        meta: {
          title: '我的',
          isSub: false
        },
        component: () => import(/* webpackChunkName: "mine" */ '../views/user/User')
      },
      {
        path: '/search-page',
        name: 'searchLayout',
        component: () => import(/* webpackChunkName: "searchLayout" */ '../views/orientation/search-middle-page')
      },
      ...commonRouters,
      ...orientationRouters,
      ...searchRouters,
      ...settingsRouters
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.path !== from.path) {
    NProgress.start()
  }
  const isLogin = localStorage.getItem('isLogin')

  if (isLogin) {
    // 判断是否有权限信息
    const hasMenu = store.getters['permission/hasMenuList']
    // 请求权限
    if (!hasMenu) {
      await store.dispatch('permission/getUserPermission') // 获取权限，后面跳转需要判断
    }
    const menus = store.state.permission.menus

    const auth = to.meta.auth // 需要权限的就判断
    if (auth) {
      const hasAuth = menus.find(el => el.sourceKey === auth)
      if (!hasAuth) {
        next('/home') // 没有权限返回首页
        return
      }
    }
    next()
  } else {
    if (to.path !== '/login') {
      next('/login')
    } else {
      next()
    }
  }

  requestCount.value = 0
  store.commit('hideLoading')
})

router.afterEach(() => {
  NProgress.done()
})

export default router
