import { deepClone } from '../../utils/common'

const initData = {
  carNumber: '', //车牌
  deviceId: '',
  source: '', // 问题来源的code
  sourceText: '全部', // 问题来源的描述
  startTime: '', // 开始时间
  endTime: '', // 截止时间
  searchKey: '', // 搜索关键字
  selectedRowData: {}
}
const state = () => deepClone(initData)
const mutations = {
  resetState(state) {
    const copyData = deepClone(initData)
    Object.keys(copyData).forEach(key => {
      state[key] = copyData[key]
    })
  },
  updateState(state, payload) {
    Object.keys(payload).forEach(key => {
      state[key] = payload[key]
    })
  },
  selectCar(state, { carNumber }) {
    state.carNumber = carNumber
  },
  updateCheckData(state, payload) {
    state.selectedRowData = payload
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
