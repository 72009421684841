export default [
  {
    path: '/search-car',
    name: 'SearchCar',
    meta: {
      title: '车辆搜索',
      isSub: false, //是否需要返回上级选项
      keepAlive: true,
      isBack: false, //是否由下级页面返回
      auth: 'desktop:h5:search-car'
    },
    component: () => import(/* webpackChunkName: "search" */ '../views/searchCar/SearchCar'),
    beforeEnter(to, from) {
      if (from.name === 'SearchResult') {
        to.meta.isBack = true
      }
    }
  },
  {
    path: '/search-result',
    name: 'SearchResult',
    meta: {
      title: '',
      isSub: true, //是否需要返回上级选项
      auth: 'desktop:h5:search-car'
    },
    component: () => import(/* webpackChunkName: "search" */ '../views/searchCar/searchResult/index'),
    children: [
      {
        path: 'reset',
        name: 'ResetID',
        meta: {
          title: '重置ID',
          isSub: true //是否需要返回上级选项
        },
        component: () => import(/* webpackChunkName: "search" */ '../views/searchCar/resetID/index.vue'),
        children: [
          {
            path: 'upgrade',
            name: 'UpGradeRecords',
            meta: {
              title: '设备升级',
              isSub: true //是否需要返回上级选项
            },
            component: () =>
              import(
                /* webpackChunkName: "search" */ '../views/searchCar/resetID/components/UpGradeRecords/UpGradeRecords'
              ),
            children: [
              {
                path: 'operate',
                name: 'UpgradeOperate',
                meta: {
                  title: '网络升级',
                  isSub: true
                },
                component: () =>
                  import(
                    /* webpackChunkName: "search" */ '../views/searchCar/resetID/components/UpgradeOperate/UpgradeOperate'
                  )
              },
              {
                path: '/requests',
                name: 'UpgradeRequests',
                meta: {
                  title: '升级记录',
                  isSub: true
                },
                component: () =>
                  import(
                    /* webpackChunkName: "search" */ '../views/searchCar/resetID/components/UpgradeRequests/UpgradeRequests'
                  )
              },
              {
                path: 'query',
                name: 'UpgradeQuery',
                meta: {
                  title: '固件查询',
                  isSub: true
                },
                component: () =>
                  import(
                    /* webpackChunkName: "search" */ '../views/searchCar/resetID/components/UpgradeQuery/UpgradeQuery'
                  )
              }
            ]
          },
          {
            path: 'reset-channel',
            name: 'ResetChannel',
            meta: {
              title: '系数配置',
              isSub: true
            },
            component: () =>
              import(/* webpackChunkName: "search" */ '../views/searchCar/resetID/components/ResetChannel/ResetChannel')
          }
        ]
      },
      {
        path: '/gps',
        name: 'GPSList',
        meta: {
          title: '',
          isSub: true
        },
        component: () => import(/* webpackChunkName: "search" */ '@/views/searchCar/searchResult/components/GPSList'),
        beforeEnter(to) {
          to.meta.title = to.query.title + '-GPS详细问题'
        }
      },
      {
        path: '/sensor',
        name: 'SensorList',
        meta: {
          title: '',
          isSub: true
        },
        component: () =>
          import(/* webpackChunkName: "search" */ '@/views/searchCar/searchResult/components/SensorList'),
        beforeEnter(to) {
          to.meta.title = to.query.title + '-传感器详细问题'
        }
      },
      {
        path: '/query-order',
        name: 'QueryOrder',
        meta: {
          title: '订单监控',
          isSub: true //是否需要返回上级选项
        },
        component: () => import(/* webpackChunkName: "search" */ '../views/searchCar/queryOrder/QueryOrder')
      },
      {
        path: '/device-detail/:id',
        name: 'OrderManageDeviceDetail',
        meta: {
          title: '订单详情',
          isSub: true
        },
        component: () => import(/* webpackChunkName: "search" */ '../views/deviceDetail/DeviceDetail')
      },
      {
        path: '/query-order/options',
        name: 'SingleQueryOptions',
        meta: {
          title: '查询',
          isSub: true //是否需要返回上级选项
        },
        component: () =>
          import(/* webpackChunkName: "search" */ '../views/searchCar/queryOrder/queryOptions/QueryOptions'),
        children: [
          {
            path: 'company',
            name: 'QueryOptionCompany',
            meta: {
              title: '选择公司',
              isSub: true
            },
            component: () =>
              import(/* webpackChunkName: "search" */ '../views/searchCar/queryOrder/queryOptions/SelectCompany')
          },
          {
            path: 'car',
            name: 'QueryOptionCar',
            meta: {
              title: '选择车辆',
              isSub: true
            },
            component: () =>
              import(/* webpackChunkName: "search" */ '../views/searchCar/queryOrder/queryOptions/SearchCar')
          }
        ]
      },
      {
        path: '/search-result/signal',
        name: 'SignalTest',
        meta: {
          title: '信噪比检测',
          isSub: true //是否需要返回上级选项
        },
        component: () => import(/* webpackChunkName: "search" */ '../views/searchCar/signalTest/index.vue')
      },
      {
        path: '/search-result/edit-car',
        name: 'SearchEditNumber',
        meta: {
          title: '编辑车辆',
          isSub: true //是否需要返回上级选项
        },
        component: () => import(/* webpackChunkName: "search" */ '../views/common/ChangeNumber/ChangeNumber')
      },
      {
        path: 'device-repair',
        name: 'DeviceRepair',
        meta: {
          title: '维修查询',
          isSub: true
        },
        component: () => import(/* webpackChunkName: "search" */ '../views/deviceRepair/DeviceRepair.vue'),
        children: [
          {
            path: 'detail',
            name: 'DeviceRepairDetail',
            meta: {
              title: '维修记录',
              isSub: true
            },
            component: () => import(/* webpackChunkName: "search" */ '../views/deviceRepair/RepairDetail')
          },
          {
            path: 'chart',
            name: 'DeviceRepairEchart',
            meta: {
              title: '待维修记录',
              isSub: true
            },
            component: () => import(/* webpackChunkName: "search" */ '../views/deviceRepair/RepairEchart')
          }
        ]
      },
      {
        path: '/search-result/garbage/:device',
        name: 'OnceGarbage',
        meta: {
          title: '单次称重明细',
          isSub: true //是否需要返回上级选项
        },
        component: () => import(/* webpackChunkName: "search" */ '../views/searchCar/onceGarbage/index.vue')
      }
    ]
  }
]
