const initData = {
  carNumber: '',
  companyId: '',
  companyName: '',
  companyType: '1', // 厂区类型
  companyTypeStr: '提货厂区',
  dateType: '1', //过滤条件:时间类型,
  dateTypeStr: '进厂时间', // 用来展示的字段
  detailStatus: '0', // 处理状态
  detailStatusStr: '待处理',
  deviceId: '',
  useTypeL1: '', //过滤条件:车辆分类一级
  useTypeL1Str: '',
  useTypeL2: '', //过滤条件:车辆分类二级
  useTypeL2Str: '',
  programType: '', // 问题分类
  programTypeStr: '',
  isGpsError: '', // GPS状态
  isGpsErrorStr: '全部',
  queryCheckStatus: '', // 订单状态
  queryCheckStatusStr: '全部',
  orderStatus2: '', // 订单进度
  orderStatus2Str: '全部',
  isFixWeight: '', // 算法
  isFixWeightStr: '全部',
  startTime: '',
  endTime: ''
}
const state = () => ({
  ...initData,
  btnNum: 'all'
})
const mutations = {
  resetState(state) {
    Object.keys(initData).forEach(key => {
      state[key] = initData[key]
    })
    state.btnNum = 'all'
  },
  updateState(state, payload) {
    Object.keys(payload).forEach(key => {
      if (Object.keys(initData).includes(key)) state[key] = payload[key]
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
