<template>
  <loading v-show="$store.state.loading" />
  <router-view />
</template>

<script>
import Loading from '@/components/Loading.vue'
import { useStore } from 'vuex'
import jsBridge from '@/utils/jsBridge'
export default {
  components: { Loading },
  name: 'App',
  setup() {
    jsBridge.nativeCallJs()
    const store = useStore()
    const userAgent = navigator.userAgent
    if (userAgent.indexOf('_Web') != -1) {
      const isIOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      store.commit('updateIOSState', isIOS)
    }
  },
  mounted() {
    // 解决移动端 100vh 包括底部工具栏的问题，自定义 --vh
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    // 软键盘弹出可能导致高度变化，这里不调整高度
    // We listen to the resize event
    // window.addEventListener('resize', () => {
    //   // We execute the same script as before
    //   let vh = window.innerHeight * 0.01
    //   document.documentElement.style.setProperty('--vh', `${vh}px`)
    // })
  }
}
</script>
<style>
#app {
  width: 100%;
  transform: translate(0, 0);
}
</style>
