export default [
  {
    path: '/create-company',
    name: 'CreateCompany',
    meta: {
      title: '添加公司',
      isSub: true
    },
    component: () => import(/* webpackChunkName: "common" */ '../views/common/CreateCompany')
  },

  {
    path: '/select-company/:section',
    name: 'SelectCompany',
    meta: {
      title: '选择公司',
      keepAlive: true, // 缓存页面
      isSub: true //是否需要返回上级选项
    },
    component: () => import(/* webpackChunkName: "common" */ '../views/common/selectCompany/index')
  }
]
