import { http_clean } from '@/utils/request'

const state = () => ({
  menus: []
})
const actions = {
  // 获取权限
  async getUserPermission({ commit }) {
    try {
      // 默认没有
      let per = []
      const auth = await http_clean.post('index2.html')
      if (auth.data && auth.data.menu) {
        per = auth.data?.menu ?? []
      }
      commit('updatePermission', per)
    } catch (error) {
      console.log('获取权限失败--->', error)
    }
  }
}
const mutations = {
  updatePermission(state, payload) {
    payload && (state.menus = payload)
  },
  removePermission(state) {
    state.menus = []
  }
}
const getters = {
  // 判断是否获取了 menu
  hasMenuList(state) {
    return state.menus.length > 0
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
