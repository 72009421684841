import store from '@/store'
import { http_clean } from '@/utils/request'
import { NATIVE_TYPE } from '@/utils/types'
import { encodeLocalStr, decodeLocalStr } from '@/utils/common'

import eventBus, { APP_ACTIVE } from '@/utils/eventBus'

class JsBridge {
  constructor() {
    this._windows = window
  }
  // versions updateVersions app版本号
  // installCount 推送安装数据
  jsCallNative(type, info) {
    const userAgent = navigator.userAgent
    if (userAgent.indexOf('_Web') != -1) {
      const isAndroid = userAgent.indexOf('Android') > -1 || userAgent.indexOf('Linux') > -1 //安卓
      const isIOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isAndroid) {
        this._windows.android.getInfoFromJs(type, JSON.stringify(info))
      }
      if (isIOS) {
        if (this._windows.webkit) {
          this._windows.webkit.messageHandlers.getInfoFromJs.postMessage({ type, info: JSON.stringify(info) })
        }
      }
    }
  }

  nativeCallJs() {
    this._windows.sendInfoToJs = (type, info) => {
      const that = this
      const userInfo = decodeLocalStr('userInfo')
      switch (type) {
        case 'versions':
          store.commit('system/setCurrentVersions', info)
          break
        case 'logInInfo':
          if (!userInfo.userId) {
            getUserInfo(localStorage.WEBSIDE_SID)
          }
          break

        case 'lifeCycle':
          // console.log('接受app激活事件')
          eventBus.emit(APP_ACTIVE, info)
          break
        default:
          break
      }
    }
  }
}

export default new JsBridge()

async function getUserInfo(token) {
  const userInfo = decodeLocalStr('HD_PLATE_H5')
  let accountInfo, userId
  // 判断本地是否有 userId
  if (!userInfo.userId) {
    accountInfo = await http_clean.post('/user/userBasic.html')
    userId = accountInfo.data.id
  } else {
    userId = userInfo.userId
  }

  const auth = await http_clean.post('index2.html')
  // 判断是否能查看车辆信息
  const hasDataView = auth.data.menu.find(el => el.sourceKey === 'desktop:baseData')

  // 判断是否有数据统计权限
  const loginData = { userId, token }

  if (hasDataView) {
    loginData.auth = true
  } else {
    loginData.auth = false
  }

  JsBridge.jsCallNative(NATIVE_TYPE.LOGIN, loginData)

  encodeLocalStr('userInfo', { ...userInfo, userId })
}
