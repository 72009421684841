<template>
  <div class="g-loading">
    <van-loading
      class="loading-content"
      type="spinner"
      size="24px"
      color="#fff"
      vertical
      style="background-color: #4c4c4c"
    >
      {{ loadText }}
    </van-loading>
  </div>
</template>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { Loading } from 'vant'
export default {
  name: 'LOADING',
  components: {
    [Loading.name]: Loading
  },
  setup() {
    const store = useStore()
    return {
      loadText: computed(() => store.state.loadText)
    }
  }
}
</script>
<style lang="scss" scoped>
.g-loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  width: 100%;
  height: 100%;
  background: transparent;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  .loading-content {
    width: 110px;
    height: 70px;
    border-radius: 8px;
    padding-top: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .van-loading__text {
    color: #fff;
  }
}
</style>
