<template>
  <div class="navbar">
    <div class="navbar__status"></div>
    <div :class="['navbar__main', { hiddenHeight: isSearch }]">
      <div class="navbar__left">
        <div class="navbar__left__return" v-if="isReturn" @click="onClickLeft">
          <van-icon name="arrow-left" />
        </div>
      </div>
      <div class="navbar__title">
        {{ $route.meta.title }}
      </div>
      <!-- 右侧插槽区域 -->
      <div class="navbar__right">
        <div class="navbar__right__function margin-auto-x" id="rightExtension" ref="popRef">
          <!-- 自定义内容 -->
          <slot name="right"></slot>
          <!-- 下拉显示功能区 -->
          <div class="navbar__right__function__extension" v-show="addOperationIsShow">
            <slot name="extension"></slot>
          </div>
        </div>
        <div class="mask" v-if="isMask"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, reactive, toRefs, watch, onMounted, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import useClickOutside from '../../hooks/useClickOutside'

export default {
  name: 'NavBar',
  props: {
    isReturn: {
      type: Boolean,
      default: true
    },
    isSearch: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { slots }) {
    const popRef = ref(null)
    const isClickOutside = useClickOutside(popRef)
    const carInfo = ref(null)

    const state = reactive({
      isMask: false,
      addOperationIsShow: false
    })
    // const isResultClickOutside = useClickOutside(carInfo)
    watch(isClickOutside, () => {
      if (state.addOperationIsShow && isClickOutside.value) {
        state.addOperationIsShow = false
        state.isMask = false
      }
    })
    const router = useRouter()
    const onClickLeft = () => {
      router.go(-1)
    }

    let triggerEl = null
    const triggerEvent = () => {
      state.addOperationIsShow = !state.addOperationIsShow
      state.isMask = !state.isMask
    }
    onMounted(() => {
      if (slots.extension) {
        triggerEl = document.querySelector('#rightExtension')
        triggerEl.addEventListener('click', triggerEvent)
      }
    })
    onBeforeUnmount(() => {
      if (triggerEl) triggerEl.removeEventListener('click', triggerEvent)
    })
    return {
      ...toRefs(state),
      carInfo,
      popRef,
      onClickLeft
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/style/variables.scss';
@import '@/style/mixins.scss';
.navbar {
  box-sizing: border-box;
  z-index: 50;
  color: $font-color;
  box-shadow: 0 2px 12px 0 rgba(32, 40, 74, 0.05);
  &__status {
    height: 44px;
    background-color: transparent;
  }
  &__main {
    height: 44px;
    line-height: 44px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    font-size: 16px;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: all 0.25s ease-out;
    &.hiddenHeight {
      height: 0;
      overflow: hidden;
      opacity: 0;
      transform: translate3d(0, -44px, 0);
      transition: all 0.25s ease-in;
    }
  }
  &__left {
    box-sizing: border-box;
    padding-left: 8px;
    width: 40px;
  }
  &__title {
    text-align: center;
    font-size: 18px;
    width: 100px;
    @include title-font;
    @include ellipsis;
  }
  &__right {
    padding-left: 8px;
    min-width: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    &__function {
      &__extension {
        line-height: 20px;
        padding: 10px 8px;
        border-radius: 4px;
        position: absolute;
        top: 42px;
        right: 8px;
        background-color: #323233;
        color: #fff;
        z-index: 60;
      }
      &__extension::after {
        content: '';
        position: absolute;
        height: 0;
        width: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 10px solid #323233;
        top: -9px;
        right: 10px;
      }
    }
  }
}

.mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 51;
}
</style>
