const state = () => ({
  protocolVersion: '2', // 协议版本
  companyId: '', // 公司 ID
  //   companyName: '', // 公司名称
  hwVersion: '', // 硬件版本
  useTypeL2: '', // 车辆分类
  //   use: '', // 车辆分类名称
  searchKey: '' // 版本号
})

const mutations = {
  updateState(state, payload) {
    Object.keys(payload).forEach(key => {
      state[key] = payload[key]
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
