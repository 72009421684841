import {
  Button,
  Col,
  Row,
  Checkbox,
  CheckboxGroup,
  Cell,
  CellGroup,
  Picker,
  Popup,
  DatetimePicker,
  IndexBar,
  IndexAnchor,
  Search,
  Empty,
  Icon,
  Field,
  Cascader,
  PasswordInput,
  NumberKeyboard,
  Badge,
  DropdownMenu,
  DropdownItem,
  PullRefresh,
  List,
  RadioGroup,
  Radio,
  Grid,
  GridItem,
  Stepper,
  Switch,
  Loading,
  Skeleton,
  ConfigProvider,
  Overlay
} from 'vant'
import { Image as VanImage } from 'vant'
export default [
  Button,
  Col,
  Row,
  Checkbox,
  CheckboxGroup,
  Cell,
  CellGroup,
  Picker,
  Popup,
  DatetimePicker,
  IndexBar,
  IndexAnchor,
  Search,
  Empty,
  Icon,
  Field,
  Cascader,
  PasswordInput,
  NumberKeyboard,
  Badge,
  DropdownMenu,
  DropdownItem,
  PullRefresh,
  List,
  RadioGroup,
  Radio,
  Grid,
  GridItem,
  VanImage,
  Stepper,
  Switch,
  Loading,
  Skeleton,
  ConfigProvider,
  Overlay
]
