const state = () => ({
  carNumber: '', // 车牌
  deviceId: '', // 设备ID
  carUploadDate: '', // 更新时间，重量更新时间
  gpsUploadDate: '',
  runStatus: '', // 设备状态，1 在线  0 离线
  // 以上信息，点击搜索选中结果时写入
  companyName: '', // 公司名称
  companyId: '', // 公司ID
  phone: '', // 手机号码
  version: '', // 算法版本，重置ID里的原始版本
  sensorChannel: [],
  hwVersion: '',
  coef: [],
  sensorProblemList: [],
  sensorList: [],
  // 以上信息进入详情页
  queryOptions: {
    // 订单监控查询条件
    dateType: '1', // 默认进厂时间
    dateTypeStr: '进厂时间',
    startTime: '',
    endTime: '',
    carNumber: '',
    deviceId: ''
  }
})
const getters = {
  coefficient(state) {
    if (state.sensorChannel === null || !state.sensorChannel) return ''
    let result = state.sensorChannel.reduce((acc, current) => {
      let temp = acc
      if (Number(current) !== 0 && state.coef[Number(current) - 1] !== 0) {
        temp =
          acc !== ''
            ? acc + ',' + `CH${current}:${state.coef[Number(current) - 1]}`
            : `CH${current}:${state.coef[Number(current) - 1]}`
      }
      return temp
    }, '')
    if (result.length === 0) return
    return '[' + result + ']'
  }
}
const mutations = {
  updateState(state, payload) {
    Object.keys(payload).forEach(key => {
      state[key] = payload[key]
    })
  },
  updateVersion(state, { version, sensorChannel, coef }) {
    state.version = version
    state.sensorChannel = sensorChannel
    state.coef = coef
  },

  updateOption(state, payload) {
    state.queryOptions = { ...state.queryOptions, ...payload }
  },
  resetOptions(state, { carNumber, deviceId }) {
    state.queryOptions = {
      dateType: '1',
      dateTypeStr: '进厂时间',
      startTime: '',
      endTime: '',
      carNumber,
      deviceId
    }
  },
  storeCarInfo(state, { carNumber, deviceId, gpsUploadDate, carUploadDate, runStatus }) {
    state.carNumber = carNumber
    state.deviceId = deviceId
    state.gpsUploadDate = gpsUploadDate
    state.carUploadDate = carUploadDate
    state.runStatus = runStatus
  },
  updateCarInfo(state, { carNumber, deviceId }) {
    state.carNumber = carNumber
    state.deviceId = deviceId
  },
  updateSensor(state, payload) {
    state.sensorList = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
