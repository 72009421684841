import { formatTimeStr } from '@/utils/common'

const current = new Date()
const initData = {
  apiType: '1',
  companyName: '',
  companyId: '',
  carNumber: '',
  deviceId: '',
  startTime: formatTimeStr(new Date(current.getTime() - 1000 * 60 * 60 * 24)),
  endTime: formatTimeStr(current)
}
const state = () => ({ ...initData })
const mutations = {
  updateState(state, payload) {
    Object.keys(payload).forEach(key => {
      state[key] = payload[key]
    })
  },
  selectCompany(state, { companyName, companyId }) {
    state.companyName = companyName
    state.companyId = companyId
    state.carNumber = ''
    state.deviceId = ''
  },
  selectCar(state, { carNumber, deviceId }) {
    state.carNumber = carNumber
    state.deviceId = deviceId
  },
  updateApiType(state, { apiType }) {
    state.apiType = apiType
  },
  resetState(state) {
    Object.keys(initData).forEach(key => {
      state[key] = initData[key]
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
