import store from '@/store'

export default [
  {
    path: '/orientation',
    name: 'Orientation',
    meta: {
      title: '车辆列表',
      keepAlive: true,
      isSub: false
    },
    component: () => import(/* webpackChunkName: "orientation" */ '../views/orientation/companies-index/index.vue')
  },
  {
    path: '/car-list/:id',
    name: 'CarList',
    meta: {
      title: '公司详情',
      isSub: true
    },
    component: () => import(/* webpackChunkName: "orientation" */ '../views/orientation/car-list/index.vue'),
    beforeEnter(to) {
      to.meta.title = to.query.companyName
    }
  },
  {
    path: '/car-detail',
    name: 'CarDetail',
    meta: {
      title: '',
      isSub: true
    },
    component: () => import(/* webpackChunkName: "orientation" */ '../views/orientation/car-detail/index.vue'),
    beforeEnter(to) {
      to.meta.title = store.state.carInfo.carNumber ? store.state.carInfo.carNumber : to.query.title
    }
  }
]
