// 车辆类型
export const carTypeList = [
  {
    value: '00',
    text: '其他',
    keyword: 'useTypeL1',
    children: [{ value: '0000', text: '其他', keyword: 'useTypeL2' }]
  },
  {
    value: '01',
    text: '水泥车',
    keyword: 'useTypeL1',
    children: [
      { value: '0100', text: '其他', keyword: 'useTypeL2' },
      { value: '0101', text: '散装水泥罐车', keyword: 'useTypeL2' },
      { value: '0102', text: '袋装水泥汽车', keyword: 'useTypeL2' },
      { value: '0104', text: '袋装平板挂车', keyword: 'useTypeL2' },
      { value: '0103', text: '混凝土搅拌车', keyword: 'useTypeL2' },
      { value: '0105', text: '物资车', keyword: 'useTypeL2' }
    ]
  },
  {
    value: '02',
    text: '环卫车',
    keyword: 'useTypeL1',
    children: [
      { value: '0200', text: '其他', keyword: 'useTypeL2' },
      { value: '0210', text: '后挂垃圾车[单桶]', keyword: 'useTypeL2' },
      { value: '0211', text: '后挂垃圾车[双桶]', keyword: 'useTypeL2' },
      { value: '0212', text: '后挂垃圾车[三桶]', keyword: 'useTypeL2' },
      { value: '0213', text: '侧挂垃圾车[链条]', keyword: 'useTypeL2' },
      { value: '0214', text: '侧挂垃圾车[拉杆]', keyword: 'useTypeL2' },
      { value: '0203', text: '勾臂车垃圾车', keyword: 'useTypeL2' },
      { value: '0204', text: '摆臂式垃圾车', keyword: 'useTypeL2' },
      { value: '0208', text: '尾板垃圾车[8桶]', keyword: 'useTypeL2' },
      { value: '0206', text: '尾板垃圾车[4.2M]', keyword: 'useTypeL2' },
      { value: '0205', text: '尾板垃圾车[4.2M平板]', keyword: 'useTypeL2' },
      { value: '0207', text: '尾板垃圾车[4.2M不封顶]', keyword: 'useTypeL2' },
      { value: '0215', text: '压缩式垃圾车[整车]', keyword: 'useTypeL2' },
      { value: '0209', text: '三轮垃圾车', keyword: 'useTypeL2' }
    ]
  },
  {
    value: '03',
    text: '厢式货车',
    keyword: 'useTypeL1',
    children: [
      { value: '0300', text: '其他', keyword: 'useTypeL2' },
      { value: '0301', text: '箱式货车[4.2M]', keyword: 'useTypeL2' },
      { value: '0302', text: '箱式货车[7.6M]', keyword: 'useTypeL2' },
      { value: '0303', text: '箱式货车[9.6M]', keyword: 'useTypeL2' },
      { value: '0304', text: '依维柯', keyword: 'useTypeL2' }
    ]
  },
  {
    value: '04',
    text: '渣土车',
    keyword: 'useTypeL1',
    children: [
      { value: '0400', text: '其他', keyword: 'useTypeL2' },
      { value: '0401', text: '碴土车[2轴]', keyword: 'useTypeL2' },
      { value: '0402', text: '碴土车[3轴]', keyword: 'useTypeL2' },
      { value: '0403', text: '碴土车[4轴]', keyword: 'useTypeL2' }
    ]
  },
  {
    value: '05',
    text: '挂车',
    keyword: 'useTypeL1',
    children: [
      { value: '0500', text: '其他', keyword: 'useTypeL2' },
      { value: '0501', text: '箱式挂车', keyword: 'useTypeL2' },
      { value: '0502', text: '平板挂车', keyword: 'useTypeL2' }
    ]
  },
  {
    value: '06',
    text: '煤炭车',
    keyword: 'useTypeL1',
    children: [{ value: '0601', text: '煤炭车', keyword: 'useTypeL2' }]
  },
  {
    value: '07',
    text: '骨料车',
    keyword: 'useTypeL1',
    children: [{ value: '0701', text: '骨料车', keyword: 'useTypeL2' }]
  },
  {
    value: '08',
    text: '混凝土搅拌车',
    keyword: 'useTypeL1',
    children: [{ value: '0801', text: '混凝土搅拌车', keyword: 'useTypeL2' }]
  }
]

export const carDetailUseList = {
  '0000': '其他',
  '0100': '其他',
  '0101': '散装水泥罐车',
  '0102': '袋装水泥汽车',
  '0104': '袋装平板车',
  '0103': '混凝土搅拌车',
  '0105': '物资车',
  '0200': '其他',
  '0210': '后挂垃圾车[单桶]',
  '0211': '后挂垃圾车[双桶]',
  '0212': '后挂垃圾车[三桶]',
  '0213': '侧挂垃圾车[链条]',
  '0214': '侧挂垃圾车[拉杆]',
  // '0201':'侧挂式垃圾车',  //该数据已删除
  // '0202':'压缩式垃圾车', //该数据已删除
  '0203': '勾臂车垃圾车',
  '0204': '摆臂式垃圾车',
  '0208': '尾板垃圾车[8桶]',
  '0206': '尾板垃圾车[4.2M]',
  '0205': '尾板垃圾车[4.2M平板]', //名字更改 旧名称：餐厨式垃圾车
  '0207': '尾板垃圾车[4.2M不封顶]',
  '0215': '压缩式垃圾车[整车]',
  '0209': '三轮垃圾车',
  '0300': '其他',
  '0301': '箱式货车[4.2M]',
  '0302': '箱式货车[7.6M]',
  '0303': '箱式货车[9.6M]',
  '0304': '依维柯',
  '0400': '其他',
  '0401': '碴土车[2轴]',
  '0402': '碴土车[3轴]',
  '0403': '碴土车[4轴]',
  '0500': '其他',
  '0501': '箱式挂车',
  '0502': '平板挂车',
  '0601': '煤炭车',
  '0701': '骨料车',
  '0801': '混凝土搅拌车'
}

export const problemTypeList = [
  {
    name: '算法',
    children: [
      {
        name: '上货问题',
        phenomenon: [
          { name: '上货误判', repairGuide: null, repairWay: null, pheDescribe: null },
          { name: '未识别上货', repairGuide: null, repairWay: null, pheDescribe: null },
          { name: '上货波动', repairGuide: null, repairWay: null, pheDescribe: null },
          { name: '多次上货问题', repairGuide: null, repairWay: null, pheDescribe: null },
          { name: '上货过程中归零', repairGuide: null, repairWay: null, pheDescribe: null }
        ]
      },
      {
        name: '卸货问题',
        phenomenon: [
          { name: '卸货未及时归零', repairGuide: null, repairWay: null, pheDescribe: null },
          { name: '卸货过程中有上升', repairGuide: null, repairWay: null, pheDescribe: null },
          { name: '卸货清零后波动', repairGuide: null, repairWay: null, pheDescribe: null },
          { name: '提前卸货', repairGuide: null, repairWay: null, pheDescribe: null },
          { name: '卸货时间小于传感器时间', repairGuide: null, repairWay: null, pheDescribe: null },
          { name: '过完磅清零', repairGuide: null, repairWay: null, pheDescribe: null },
          { name: '延时卸货', repairGuide: null, repairWay: null, pheDescribe: null }
        ]
      },
      {
        name: '在途问题',
        phenomenon: [
          { name: '途中增重量', repairGuide: null, repairWay: null, pheDescribe: null },
          { name: '途中掉重量', repairGuide: null, repairWay: null, pheDescribe: null }
        ]
      }
    ]
  },
  {
    name: '硬件',
    children: [
      {
        name: '主机问题',
        phenomenon: [
          {
            name: '离线',
            //维修指导
            repairGuide: [
              { index: 1, name: '检查GPSRS灯是否正常' },
              { index: 2, name: '手机连接主机蓝牙，检查ID和计数器是否正常' },
              { index: 3, name: '主机是否不断重启，检查20pin线，主线，采集器' },
              { index: 4, name: '检查保险是否工作正常' },
              { index: 5, name: '检查电源、主机工作状态等' }
            ],
            //维修方式
            repairWay: [
              { index: 1, name: '更换主机' },
              { index: 2, name: '更换SIM卡' },
              { index: 3, name: '更换20pin线' },
              { index: 4, name: '更换线材' },
              { index: 5, name: '更换保险' }
            ],
            //现象描述
            pheDescribe: [
              { index: 1, name: 'GPRS灯不正常' },
              { index: 2, name: '蓝牙连接后无ID显示或显示为0，计数器不跳' },
              { index: 3, name: '手机不能通过蓝牙连接主机' },
              { index: 4, name: '主机不断重启' },
              { index: 5, name: '其他' }
            ]
          },
          {
            name: '未识别上货',
            repairGuide: [{ index: 1, name: '更换主机' }],
            repairWay: [{ index: 1, name: '更换主机' }],
            pheDescribe: null
          }
        ]
      },
      {
        name: '传感器问题',
        phenomenon: [
          {
            name: '正负52万',
            repairGuide: [
              { index: 1, name: 'APP检查传感器是否显示极值' },
              { index: 2, name: '2000表检查传感器是否显示“EI”' },
              { index: 3, name: '更换传感器' }
            ],
            repairWay: [{ index: 1, name: '更换传感器' }],
            pheDescribe: [
              { index: 1, name: '传感器粘贴处胶松动' },
              { index: 2, name: '传感器粘贴处被破坏' },
              { index: 3, name: 'APP检测通道值显示52万' },
              { index: 4, name: '2000表检测通值异常' },
              { index: 5, name: '其他' }
            ]
          },
          {
            name: '偶尔52万',
            repairGuide: [
              { index: 1, name: 'APP检查传感器是否显示极值' },
              { index: 2, name: '2000表检查传感器是否显示“EI”' },
              { index: 3, name: '更换传感器' }
            ],
            repairWay: [{ index: 1, name: '更换传感器' }],
            pheDescribe: [
              { index: 1, name: '传感器粘贴处胶松动' },
              { index: 2, name: '传感器粘贴处被破坏' },
              { index: 3, name: 'APP检测通道值显示52万' },
              { index: 4, name: '2000表检测通值异常' },
              { index: 5, name: '其他' }
            ]
          },
          {
            name: '经常52万',
            repairGuide: [
              { index: 1, name: 'APP检查传感器是否显示极值' },
              { index: 2, name: '2000表检查传感器是否显示“EI”' },
              { index: 3, name: '更换传感器' }
            ],
            repairWay: [{ index: 1, name: '更换传感器' }],
            pheDescribe: [
              { index: 1, name: '传感器粘贴处胶松动' },
              { index: 2, name: '传感器粘贴处被破坏' },
              { index: 3, name: 'APP检测通道值显示52万' },
              { index: 4, name: '2000表检测通值异常' },
              { index: 5, name: '其他' }
            ]
          },
          {
            name: '超过52万',
            repairGuide: [
              { index: 1, name: '485接口松动或接触不良' },
              { index: 2, name: '手机连接主机蓝牙，确认通道值是否异常' },
              { index: 3, name: '检查接线、4pin接口等' }
            ],
            repairWay: [
              { index: 1, name: '更换主机' },
              { index: 2, name: '更换485接口连接线' },
              { index: 3, name: '检查接线、4pin接口等' }
            ],
            pheDescribe: [
              { index: 1, name: 'APP显示通道值异常' },
              { index: 2, name: '其他' }
            ]
          },
          {
            name: '零值',
            repairGuide: [
              { index: 1, name: '检查线材是否短路或断裂' },
              { index: 2, name: '检查采集器是否进水' },
              { index: 3, name: '检查接线、4pin接口等' }
            ],
            repairWay: [
              { index: 1, name: '更换采集器' },
              { index: 2, name: '更换线材' },
              { index: 3, name: '检查接线、4pin接口等' }
            ],
            pheDescribe: [{ index: 1, name: 'APP显示通道值为0' }]
          },
          {
            name: '平直',
            repairGuide: [
              { index: 1, name: '485接口松动或接触不良' },
              { index: 2, name: '检查接线' },
              { index: 3, name: '4pin接口' },
              { index: 4, name: '检查接线、4pin接口等' }
            ],
            repairWay: [
              { index: 1, name: '更换主机' },
              { index: 2, name: '更换485接口接连线' },
              { index: 3, name: '检查接线' },
              { index: 4, name: '4pin接口' },
              { index: 5, name: '检查接线、4pin接口等' }
            ],
            pheDescribe: [
              { index: 1, name: 'APP显示通道值不跳动' },
              { index: 2, name: '其他' }
            ]
          },
          {
            name: '乱跳',
            repairGuide: [
              { index: 1, name: 'APP检查传感器是否乱跳' },
              { index: 2, name: '2000表检查传感器是否乱跳' },
              { index: 3, name: '更换传感器' }
            ],
            repairWay: [{ index: 1, name: '更换传感器' }],
            pheDescribe: [
              { index: 1, name: '传感器粘贴处胶松动' },
              { index: 2, name: '传感器粘贴处被破坏' },
              { index: 3, name: 'APP检测传感器乱跳' },
              { index: 4, name: '2000表检测通值异常' },
              { index: 5, name: '其他' }
            ]
          },
          {
            name: '方向反',
            repairGuide: [{ index: 1, name: '更换传感器' }],
            repairWay: [{ index: 1, name: '更换传感器' }],
            pheDescribe: []
          },
          {
            name: '形变小',
            repairGuide: [{ index: 1, name: '更换传感器' }],
            repairWay: [{ index: 1, name: '更换传感器' }],
            pheDescribe: []
          }
        ]
      },
      {
        name: 'GPS问题',
        phenomenon: [
          {
            name: 'GPS丢失',
            repairGuide: [
              { index: 1, name: '检查gps天线是否被遮挡' },
              { index: 2, name: '检查GPS天线是否破损' },
              { index: 3, name: '检查天线' }
            ],
            repairWay: [
              { index: 1, name: '更换天线' },
              { index: 2, name: '更换终端' },
              { index: 3, name: '将天线转移至车顶' },
              { index: 4, name: '检查天线' },
              { index: 5, name: '检查天线' }
            ],
            pheDescribe: [
              { index: 1, name: '司机遮挡天线' },
              { index: 2, name: 'GPS天线破损' },
              { index: 3, name: '其他' },
              { index: 4, name: '检查天线' }
            ]
          },
          {
            name: 'GPS漂移',
            repairGuide: [
              { index: 1, name: '检查gps天线是否被遮挡' },
              { index: 2, name: '检查GPS天线是否破损' },
              { index: 3, name: '检查天线' }
            ],
            repairWay: [
              { index: 1, name: '更换天线' },
              { index: 2, name: '更换终端' },
              { index: 3, name: '将天线转移至车顶' },
              { index: 4, name: '检查天线' }
            ],
            pheDescribe: [
              { index: 1, name: '司机遮挡天线' },
              { index: 2, name: 'GPS天线破损' },
              { index: 3, name: '其他' }
            ]
          },
          {
            name: '停车速度平直',
            repairGuide: [
              { index: 1, name: '检查gps天线是否被遮挡' },
              { index: 2, name: '检查GPS天线是否破损' },
              { index: 3, name: '检查天线' }
            ],
            repairWay: [
              { index: 1, name: '更换天线' },
              { index: 2, name: '更换终端' },
              { index: 3, name: '将天线转移至车顶' },
              { index: 4, name: '检查天线' }
            ],
            pheDescribe: [
              { index: 1, name: '司机遮挡天线' },
              { index: 2, name: 'GPS天线破损' },
              { index: 3, name: '其他' }
            ]
          },
          {
            name: '无GPS',
            repairGuide: [
              { index: 1, name: '检查gps天线是否被遮挡' },
              { index: 2, name: '检查GPS天线是否破损' },
              { index: 3, name: '检查天线' }
            ],
            repairWay: [
              { index: 1, name: '更换天线' },
              { index: 2, name: '更换终端' },
              { index: 3, name: '将天线转移至车顶' },
              { index: 4, name: '检查天线' }
            ],
            pheDescribe: [
              { index: 1, name: '司机遮挡天线' },
              { index: 2, name: 'GPS天线破损' },
              { index: 3, name: '其他' }
            ]
          },
          {
            name: '大段丢失',
            repairGuide: [
              { index: 1, name: '检查gps天线是否被遮挡' },
              { index: 2, name: '检查GPS天线是否破损' },
              { index: 3, name: '检查天线' }
            ],
            repairWay: [
              { index: 1, name: '更换天线' },
              { index: 2, name: '更换终端' },
              { index: 3, name: '将天线转移至车顶' },
              { index: 4, name: '检查天线' }
            ],
            pheDescribe: [
              { index: 1, name: '司机遮挡天线' },
              { index: 2, name: 'GPS天线破损' },
              { index: 3, name: '其他' }
            ]
          },
          {
            name: '偶尔丢失',
            repairGuide: [
              { index: 1, name: '检查gps天线是否被遮挡' },
              { index: 2, name: '检查GPS天线是否破损' },
              { index: 3, name: '检查天线' }
            ],
            repairWay: [
              { index: 1, name: '更换天线' },
              { index: 2, name: '更换终端' },
              { index: 3, name: '将天线转移至车顶' },
              { index: 4, name: '检查天线' }
            ],
            pheDescribe: [
              { index: 1, name: '司机遮挡天线' },
              { index: 2, name: 'GPS天线破损' },
              { index: 3, name: '其他' }
            ]
          }
        ]
      },
      {
        name: '线材问题',
        phenomenon: [
          {
            name: '无通道',
            repairGuide: [{ index: 1, name: '检查接线、4pin接口等' }],
            repairWay: [{ index: 1, name: '检查接线、4pin接口等' }],
            pheDescribe: null
          },
          {
            name: '全部平直',
            repairGuide: [{ index: 1, name: '检查接线、4pin接口等' }],
            repairWay: [{ index: 1, name: '检查接线、4pin接口等' }],
            pheDescribe: null
          },
          {
            name: '过磅传感器不跳',
            repairGuide: [{ index: 1, name: '检查接线、4pin接口等' }],
            repairWay: [{ index: 1, name: '检查接线、4pin接口等' }],
            pheDescribe: null
          }
        ]
      },
      {
        name: '其他',
        phenomenon: null
      }
    ]
  },

  {
    name: '司机',
    children: [
      {
        name: '司机问题',
        phenomenon: [
          { name: '上货断电', repairGuide: null, repairWay: null, pheDescribe: null },
          { name: '卸货断电', repairGuide: null, repairWay: null, pheDescribe: null },
          { name: '司机修车', repairGuide: null, repairWay: null, pheDescribe: null },
          { name: '车祸', repairGuide: null, repairWay: null, pheDescribe: null }
        ]
      },
      { name: '其他', phenomenon: null }
    ]
  },
  {
    name: '对接',
    children: [
      {
        name: '对接问题',
        phenomenon: [
          { name: '未传地磅', repairGuide: null, repairWay: null, pheDescribe: null },
          { name: '重量未乘倍数', repairGuide: null, repairWay: null, pheDescribe: null },
          { name: '进场重量不为零', repairGuide: null, repairWay: null, pheDescribe: null }
        ]
      },
      { name: '其他', phenomenon: null }
    ]
  },
  {
    name: '运维',
    children: [
      {
        name: '运维问题',
        phenomenon: [
          { name: '未升级', repairGuide: null, repairWay: null, pheDescribe: null },
          { name: '未设系数', repairGuide: null, repairWay: null, pheDescribe: null },
          { name: '未调整系数', repairGuide: null, repairWay: null, pheDescribe: null }
        ]
      },
      { name: '未升级', phenomenon: null },
      { name: '系数问题', phenomenon: null },
      { name: '其他', phenomenon: null }
    ]
  },
  {
    name: '安装',
    children: [
      {
        name: '安装问题',
        phenomenon: [
          { name: '车型录入错误', repairGuide: null, repairWay: null, pheDescribe: null },
          { name: '通道顺序连接错误', repairGuide: null, repairWay: null, pheDescribe: null },
          { name: '车牌号码录入错误', repairGuide: null, repairWay: null, pheDescribe: null },
          { name: '车辆项目录入错误', repairGuide: null, repairWay: null, pheDescribe: null },
          { name: '设备版本录入错误', repairGuide: null, repairWay: null, pheDescribe: null }
        ]
      },
      { name: '其他', phenomenon: null }
    ]
  },
  {
    name: '平台',
    children: [
      {
        name: '未识别异常',
        phenomenon: [{ name: '未识别异常', repairGuide: null, repairWay: null, pheDescribe: null }]
      },
      {
        name: '算法曲线断裂',
        phenomenon: [{ name: '算法曲线断裂', repairGuide: null, repairWay: null, pheDescribe: null }]
      },
      { name: '其他', phenomenon: null }
    ]
  },
  {
    name: '磅单',
    children: [
      {
        name: '磅单问题',
        phenomenon: [{ name: '磅单异常', repairGuide: null, repairWay: null, pheDescribe: null }]
      },
      { name: '其他', phenomenon: null }
    ]
  }
]

export const NATIVE_TYPE = {
  LOGIN: 'logIn', //登录
  LOG_OUT: 'logOut', //登出
  VERSIONS: 'versions', //版本号
  UPDATE_VERSIONS: 'updateVersions', //更新版本号
  INSTALL_COUNT: 'installCount' // 更新小组件安装量
}
