// 修改车牌功能
const initData = {
  carNumber: '', //车牌
  companyName: '',
  companyId: '',
  deviceId: '' //设备ID
}
const state = () => ({
  ...initData
})
const mutations = {
  resetState(state) {
    Object.keys(initData).forEach(key => {
      state[key] = initData[key]
    })
  },
  updateState(state, payload) {
    Object.keys(payload).forEach(key => {
      state[key] = payload[key]
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
