export default [
  {
    path: '/settings',
    name: 'Settings',
    meta: {
      title: '设置',
      isSub: false,
      auth: 'desktop:h5:setting'
    },
    component: () => import(/* webpackChunkName: "setting" */ '../views/settings')
  },
  {
    path: '/settings/gps-audit',
    name: 'GPSAudit',
    meta: {
      title: 'GPS审核',
      isSub: true,
      auth: 'desktop:h5:setting'
    },
    component: () => import(/* webpackChunkName: "setting" */ '../views/settings/gpsAudit/list'),
    children: [
      {
        path: '/settings/gps-audit/audit-detail',
        name: 'GPSAuditDetail',
        meta: {
          title: '审核详情',
          isSub: true
        },
        component: () => import(/* webpackChunkName: "setting" */ '../views/settings/gpsAudit/detail/index.vue')
      },
      {
        path: '/settings/gps-query',
        name: 'GpsQueryOptions',
        meta: {
          title: 'GPS查询',
          isSub: true
        },
        component: () => import(/* webpackChunkName: "setting" */ '../views/settings/gpsAudit/queryOptions')
      }
    ]
  },
  {
    path: '/settings/search-car',
    name: 'GpsSearchCar',
    meta: {
      title: '车辆列表',
      isSub: true,
      auth: 'desktop:h5:setting'
    },
    component: () => import(/* webpackChunkName: "setting" */ '../views/settings/gpsAudit/searchCar/index')
  },
  {
    path: '/settings/maintain',
    name: 'Maintain',
    meta: {
      title: '维修单',
      isSub: true,
      auth: 'desktop:h5:setting'
    },
    component: () => import(/* webpackChunkName: "setting" */ '../views/settings/maintain/index/index.vue'),
    children: [
      {
        path: 'search',
        name: 'MaintainSearch',
        meta: {
          title: '订单查询',
          isSub: true
        },
        component: () => import(/* webpackChunkName: "setting" */ '../views/settings/maintain/search/index')
      },
      {
        path: 'search-car',
        name: 'MaintainSearchCar',
        meta: {
          title: '车辆列表',
          isSub: true
        },
        component: () => import(/* webpackChunkName: "setting" */ '../views/settings/maintain/searchCar/index')
      },
      {
        path: 'order-detail',
        name: 'MaintainOrderDetail',
        meta: {
          title: '',
          isSub: true
        },
        component: () => import(/* webpackChunkName: "setting" */ '../views/settings/maintain/orderDetail/index'),
        beforeEnter(to) {
          to.meta.title = to.query.title
        }
      }
    ]
  },

  {
    path: '/settings/optimize',
    name: 'Optimize',
    meta: {
      title: '数据查询',
      isSub: true,
      auth: 'desktop:h5:setting'
    },
    component: () => import(/* webpackChunkName: "setting" */ '../views/settings/optimize/index')
  },
  {
    path: '/settings/optimize/search-car',
    name: 'OptimizeSearchCar',
    meta: {
      title: '车辆列表',
      isSub: true,
      auth: 'desktop:h5:setting'
    },
    component: () => import(/* webpackChunkName: "setting" */ '../views/settings/optimize/searchCar/index')
  },
  {
    path: '/settings/optimize/print',
    name: 'OptimizePrint',
    meta: {
      title: '',
      isSub: true,
      auth: 'desktop:h5:setting'
    },
    component: () => import(/* webpackChunkName: "setting" */ '../views/settings/optimize/optimizeChart/index'),
    beforeEnter(to) {
      to.meta.title = to.query.title
    }
  },
  {
    path: '/settings/sync-car',
    name: 'SettingSync',
    meta: {
      title: '同步项目车辆',
      isSub: true,
      auth: 'desktop:h5:setting'
    },
    component: () => import(/* webpackChunkName: "setting" */ '../views/settings/syncCar/index'),
    children: [
      {
        path: 'company',
        name: 'SettingSyncCompany',
        meta: {
          title: '搜索公司',
          isSub: true
        },
        component: () => import(/* webpackChunkName: "setting" */ '../views/settings/syncCar/components/findCompany')
      }
    ]
  },
  {
    path: '/settings/device-relieve-record',
    name: 'DeviceRelieveRecord',
    meta: {
      title: '设备待解除限制记录',
      isSub: true,
      auth: 'desktop:h5:setting'
    },
    component: () => import(/* webpackChunkName: "setting" */ '../views/settings/device-relieve-record/index'),
    children: [
      {
        path: 'query',
        name: 'DeviceRelieveRecordQuery',
        meta: {
          title: '查询',
          isSub: true
        },
        component: () => import(/* webpackChunkName: "setting" */ '../views/settings/device-relieve-record/query'),
        children: [
          {
            path: 'company',
            name: 'DeviceRelieveRecordQueryCompany',
            meta: {
              title: '选择公司',
              isSub: true
            },
            component: () => import(/* webpackChunkName: "setting" */ '../views/settings/device-relieve-record/company')
          },
          {
            path: 'car',
            name: 'DeviceRelieveRecordQueryCar',
            meta: {
              title: '选择车辆',
              isSub: true
            },
            component: () =>
              import(/* webpackChunkName: "setting" */ '../views/settings/device-relieve-record/selectCar')
          }
        ]
      }
    ]
  }
]
