import { http_clean } from '@/utils/request'
import { toTree } from '@/utils/common'

const state = () => ({
  companyData: null,
  locationCompany: null
})
const actions = {
  async companyTreeRequest({ commit }) {
    try {
      const data = await http_clean.post('company/companytree.html')
      const companies = toTree(data)
      commit('updateCompanies', companies)
    } catch (error) {
      console.log('获取公司树失败', error)
    }
  }
}
const mutations = {
  updateCompanies(state, payload) {
    payload && (state.companyData = payload)
  },
  updateLocCompany(state, payload) {
    payload && (state.locationCompany = payload)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
