import axios from 'axios'
import qs from 'qs'
import router from '@/router'
import store from '@/store'
import { Toast } from 'vant'

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 1000 * 60
})

export const requestCount = { value: 0 }
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

instance.interceptors.request.use(
  function (config) {
    if (localStorage.WEBSIDE_SID) {
      config.headers['X-Token'] = localStorage.WEBSIDE_SID
    }
    config.data = qs.stringify(config.data)
    // 如果没有未返回结果的接口，开始 loading
    if (requestCount.value === 0) {
      store.commit('showLoading')
    }
    // 记录未返回结果的接口数
    requestCount.value++
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)
instance.interceptors.response.use(
  function (response) {
    response = response.data
    if (response.code === 103) {
      // 清除 isLogin，localStorage 中数据都会被转换成字符串
      // 设置 false 会变成 'false'
      localStorage.removeItem('isLogin')
      requestCount.value = 0
      Toast('登录信息过期，请重新登录')
      setTimeout(() => {
        router.push({
          name: 'Login'
        })
      }, 1500)
    }
    if (requestCount.value > 0) {
      requestCount.value--
      if (requestCount.value === 0) {
        // 如果全部请求已经返回，隐藏 loading
        store.commit('hideLoading')
      }
    }

    return response
  },
  function (error) {
    // 请求失败，不再 loading
    requestCount.value = 0
    store.commit('hideLoading')
    return Promise.reject(error)
  }
)
export default instance
// 不用 loading 的请求
export const http_clean = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 1000 * 60
})
http_clean.interceptors.request.use(
  function (config) {
    if (localStorage.WEBSIDE_SID) {
      config.headers['X-Token'] = localStorage.WEBSIDE_SID
    }
    config.data = qs.stringify(config.data)
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)
http_clean.interceptors.response.use(
  function (response) {
    response = response.data
    if (response.code === 103) {
      localStorage.removeItem('isLogin')
      requestCount.value = 0
      Toast('登录信息过期，请重新登录')
      setTimeout(() => {
        router.push({
          name: 'Login'
        })
      }, 1500)
    }
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)
