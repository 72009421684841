const state = () => ({
  currentVersions: '1.0.0' //版本号
})

const mutations = {
  setCurrentVersions: (state, versions) => {
    const versionsObj = JSON.parse(versions)
    state.currentVersions = versionsObj.version
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
