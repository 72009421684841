const initData = {
  carNumber: '', //车牌
  auditStatus: '1', // 问题来源的code
  deviceId: ''
}
const state = () => ({
  ...initData
})
const mutations = {
  resetState(state) {
    Object.keys(initData).forEach(key => {
      state[key] = initData[key]
    })
  },
  updateState(state, payload) {
    Object.keys(payload).forEach(key => {
      if (Object.hasOwnProperty.call(initData, key)) {
        state[key] = payload[key]
      }
    })
  },
  selectCar(state, { carNumber, deviceId }) {
    state.carNumber = carNumber
    state.deviceId = deviceId
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
