<template>
  <div class="wrapper">
    <div class="wrapper__top">
      <img :src="logoImgSrc" class="logo" />
    </div>
    <div class="wrapper__content">
      <div class="section">
        <div class="section__title">账号</div>
        <div class="section__input">
          <input class="inner__input" placeholder="请输入您的账号" v-model="username" @blur="backTop" />
        </div>
      </div>
      <div class="section">
        <div class="section__title">密码</div>
        <div class="section__input">
          <input
            class="inner__input"
            placeholder="请输入您的密码"
            type="password"
            v-model="password"
            autocomplete="new-password"
            @blur="backTop"
            @keyup.enter="handleLogin"
          />
        </div>
      </div>
      <div :class="['section__login-btn', { active: loginActive }]" @click="handleLogin">登录</div>
    </div>
    <Toast v-if="show" :message="toastMessage" />
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import http from '@/utils/request'

import Toast, { useToastEffect } from '@/components/Toast.vue'

import { IS_LOGIN, PLATFORM_FLAG, TOKEN_KEY, USER_INFO } from '@/const'
import { NATIVE_TYPE } from '@/utils/types'
import { encodeLocalStr, decodeLocalStr } from '@/utils/common'
import JsBridge from '@/utils/jsBridge'
import logoImgSrc from '@/assets/img/login_logo.png'
const useLoginEffect = (showToast, store) => {
  const router = useRouter()
  const data = reactive({
    username: '',
    password: ''
  })
  onMounted(() => {
    const userInfo = decodeLocalStr('HD_PLATE_H5')
    // 自动填写账号密码
    if (userInfo) {
      data.username = userInfo?.userName ?? ''
      data.password = userInfo?.password ?? ''
    }
  })

  async function handleLogin() {
    try {
      const result = await http.post('login2.html', {
        userName: data.username,
        password: data.password,
        type: 1
      })
      if (result.success === true) {
        // 清空 localStorage
        localStorage.clear()
        // 保存原来的账号密码，防止刷新
        encodeLocalStr(PLATFORM_FLAG, {
          userName: data.username,
          password: data.password
        })
        // 请求权限
        store.dispatch('permission/getUserPermission')
        const token = result.data
        // 记录登录状态，登录页面自动跳转到 home
        localStorage.setItem(IS_LOGIN, true)
        // 记录接口获取的 token
        localStorage.setItem(TOKEN_KEY, token)
        // 记录登录信息
        encodeLocalStr(PLATFORM_FLAG, {
          userName: data.username,
          password: data.password
        })

        store.commit('token', token)
        const res = await getUserInfo(token)
        if (res === null) {
          showToast('您没有访问权限，请咨询管理员获取')
          return
        }
        encodeLocalStr(USER_INFO, res)
        // 不将 login 推入页面栈，已登录不能返回登录页
        router.replace({ name: 'Home' })
      } else {
        showToast(result.message)
      }
    } catch {
      showToast('登录请求失败')
    }
  }

  const { username, password } = toRefs(data)

  return { username, password, handleLogin }
}

export default {
  name: 'Login',
  components: { Toast },

  // 只注重主流程的执行，不同业务的函数拆分出去，业务逻辑清晰明了
  setup() {
    const store = useStore() //引入vuex
    const { show, toastMessage, showToast } = useToastEffect()
    const { username, password, handleLogin } = useLoginEffect(showToast, store)
    store.commit('updateHomeScroll', 0)

    const userAgent = navigator.userAgent
    const isIOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端

    const backTop = () => {
      if (isIOS) window.scrollTo(0, 0)
    }
    return {
      handleLogin,
      username,
      password,
      show,
      toastMessage,
      logoImgSrc,
      loginActive: computed(() => password.value),
      backTop
    }
  }
}
async function getUserInfo(token) {
  const accountInfo = await http.post('user/userBasic.html')
  // 获取权限信息
  const auth = await http.post('index2.html')
  if (!auth.data.menu) {
    return null
  }

  const loginData = { userId: accountInfo.data.id, token }
  JsBridge.jsCallNative(NATIVE_TYPE.LOGIN, loginData)
  const { name, userName } = accountInfo.data
  return { name, userName, userId: accountInfo.data.id }
}
</script>

<style lang="scss" scoped>
@import '@/style/variables.scss';

.wrapper {
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  box-sizing: border-box;
  background-image: linear-gradient(128deg, #1c74f2 0%, #35a3ff 100%);
  display: flex;
  flex-direction: column;
  .input__content {
    margin-top: 80px;
  }
  &__top {
    height: 252px;
    .logo {
      height: 50px;
      width: 137px;
      margin-top: 114px;
      margin-left: 35px;
    }
  }
  &__content {
    background-color: #fff;
    flex: 1;
    box-sizing: border-box;
    padding-top: 40px;
    border-radius: 38px 38px 0 0;
  }
  .section {
    height: 68px;
    margin: 0 34px 24px 34px;

    &__title {
      color: #20284a;
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
    }

    &__input {
      line-height: 48px;
      width: 100%;
      border-bottom: 1px solid #cccccc;

      .inner__input {
        border: none;
        outline: none;
        font-size: 14px;
        line-height: 20px;
        color: $notice-font-color;
        &::placeholder {
          color: $notice-font-color;
        }
      }
    }

    &__login-btn {
      margin: 32px 40px 16px;
      line-height: 48px;
      background: #92c8ff;
      box-shadow: 0 4px 8px 0 rgba(0, 145, 255, 0.32);
      border-radius: 999px;
      color: white;
      font-size: 16px;
      text-align: center;
    }
    &__login-btn.active {
      background-image: linear-gradient(128deg, #1c74f2 0%, #35a3ff 100%);
    }
  }
}
</style>
